const cardBodyStyle = {
  menuItem: {
    color: "inherit",
    boxShadow: "none",
    padding: "0 1.875rem",
    flex: "1",
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
    width: "100%",
    minHeight: "25vh",
    overflow: "hidden",
    cursor: "pointer",    
    borderBottom: "20px ridge #D6B7C8",
    zIndex: 1,
    transition: "flex-grow .2s, opacity .2s",
    "&:before": {
      content: "\"\"",
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      transition: "transform .2s, width .2s"
    },
    "&:after": {
      content: "\"\"",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgb(200, 200, 200)",
      opacity: 0,
      transition: "opacity .2s",
    },
    "&:hover": {      
      textDecoration: "none",
      flex: "1.5",
      "&:after": {
        opacity: ".05"
      },
      "& div": {
        opacity: 1,
        transform: "translateY(0)",
        transition: "opacity .2s .2s, transform .2s .2s",
        "@media (min-width: 630px)": {
          transform: "translateY(0) skewX(10deg)" 
        }
      }
    },
    "@media (min-width: 630px)": {
      width: "25%",
      height: "400px",
      marginRight: "-1px",
      backgroundColor: "rgb(255, 250, 255)",
      borderRight: "20px ridge #D6B7C8",
      borderBottom: "none",
      transform: "skewX(-10deg) translateZ(0)",
      "&:last-child": {
        borderRight: "none",
      },
      "&:before": {
        left: "-48%",
        width: "200%",
        transform: "skewX(10deg)"
      }
    },
    "& div": {
      fontSize: "1.5em",
      marginBottom: "10px",
      marginLeft: "20px",
      transition: "opacity .2s .2s, transform .2s .2s",
      "@media (min-width: 630px)": {
        transform: "skewX(10deg)"
      }
    }
  }
};

export default cardBodyStyle;
