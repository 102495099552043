import React from "react";
// nodejs library that concatenates classes
import clsx from "clsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import styles from "@assets/jss/components/skewMenuStyle.js";

const useStyles = makeStyles(styles);

export default function Card(props) {
  const classes = useStyles();
  const { className, headerChildren, children, footerChildren, ...rest } = props;
  const menuClasses = clsx({
    [classes.menu]: true,
    [className]: className !== undefined
  });
  return (
    <div className={menuClasses} {...rest}>
      { props.headerChildren }
      <div className={classes.container}>
        {children}
      </div>
      { props.footerChildren }
    </div>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  headerChildren: PropTypes.node,
  children: PropTypes.node,
  footerChildren: PropTypes.node
};
