import React from 'react'
import { graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { Beenhere } from '@material-ui/icons'
import Teams from '@components/teams'
import clsx from 'clsx'
import Layout from '@components/layout'
import SEO from '@components/seo'
import Link from '@components/Link'
import Parallax from '@components/Parallax/Parallax'
import Button from '@components/CustomButtons/Button'
import styles from '@assets/jss/views/components.js'
import GridContainer from '@components/Grid/GridContainer'
import GridItem from '@components/Grid/GridItem'
import SkewMenu from '@components/SkewMenu/SkewMenu'
import SkewMenuItem from '@components/SkewMenu/SkewMenuItem'
import postsStyle from '@assets/jss/posts.js'
import RecentArticles from '@components/recentArticles'
const useStyles = makeStyles({
  ...styles,
  ...postsStyle,
  button: {
    fontSize: "1.125em"
  }
})

const IndexPage = props => {
  const { data } = props
  const posts = data.news.edges  
  const teachers = data.teachers.edges
  const courses = data.courses.edges
  const siteTitle = data.site.siteMetadata.title
  const classes = useStyles()
  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="Home" keywords={[`dance`, `小舞花`]} />
      <Parallax filter normal image={require('@assets/img/demo1.jpg')}>
        <div className={classes.container} style={{ padding: "10px"}}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h2 className={classes.title}>
                  小舞花舞蹈学院{' '}
                  <span role="img" aria-label="blossom emoji">
                    🌼 
                  </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </h2>
                <p>享有盛誉的一所专业舞蹈学院.</p>
                <br />
                <Link to="/contact" underline="none">
                  <Button
                    variant="contained"
                    color="rose"
                    className={classes.button}
                    endIcon={<Beenhere />}
                  >
                    联系我们
                  </Button>
                </Link>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={clsx(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <h2 className={classes.subtitle2}>课程介绍</h2>
          <SkewMenu>
            { courses.map(({node}) => {
              return (               
                <SkewMenuItem 
                  key={node.id} 
                  to={node.frontmatter.type + node.fields.slug} 
                  backgroundImage={node.frontmatter.thumbnail.childImageSharp.fixed}
                >
                  <div>{node.frontmatter.title}<br />{node.frontmatter.titleEnglish}</div>
                </SkewMenuItem>
              )
            })}
          </SkewMenu>
          <div style={{ textAlign: "center", padding: "20px 0" }}>
            <Link to="/courses" underline="none">
              <Button variant="contained" color="rose" className={classes.button}>
                查看全部课程...
              </Button>
            </Link>
          </div>
        </div>
        <div className={classes.container}>
          <br />
          <h2 className={classes.subtitle2}>最新资讯</h2>
          <br />
          <RecentArticles posts={posts} />
        </div>
        <div className={classes.container}>
          <br />
          <h2 className={classes.subtitle2}>师资团队</h2>
          <Teams teachers={teachers} />
          <div style={{ textAlign: "center", marginBottom: "60px" }}>
            <Link to="/teachers" underline="none">
              <Button variant="contained" color="rose" className={classes.button}>
                查看全部教师介绍...
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    news: allMdx(filter: {frontmatter: {type: {eq: "news"}, visible: {eq: true}}}, sort: { fields: [frontmatter___date], order: DESC }, limit: 2) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            type
            featuredImage {
              childImageSharp {
                fixed(width: 300, height: 200, fit: COVER) {
                  ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    teachers: allMdx(filter: {frontmatter: {type: {eq: "teachers"}, visible: {eq: true}, home: {eq: true}}}, sort: { fields: [frontmatter___date], order: ASC }, limit: 3) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            position
            title
            type
            description
            avatar {
              childImageSharp {
                fixed(width: 200, height: 200, fit: COVER) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    courses: allMdx(filter: {frontmatter: {type: {eq: "courses"}, visible: {eq: true}, home: {eq: true}}}, limit: 4) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            titleEnglish
            type
            thumbnail {
              childImageSharp {
                fixed(width: 300, height: 300, fit: COVER) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
