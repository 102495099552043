import React from "react";
// nodejs library that concatenates classes
import clsx from "clsx";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import Link from '@components/Link'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import styles from "@assets/jss/components/skewMenuItemStyle.js";

const useStyles = makeStyles({
  menuItem: {
    ...styles.menuItem,
    "&:before": {
      ...styles.menuItem["&:before"],
      backgroundImage: props => "url(" + props.backgroundImage.src + ")"
    },
  }
});

export default function CardBody(props) {
  const classes = useStyles(props);
  const { className, children, to, backgroundImage, ...rest } = props;
  const menuItemClasses = clsx({
    [classes.menuItem]: true,
    [className]: className !== undefined
  });
  return (
    <Link className={menuItemClasses} to={to} {...rest}>
      {children}
    </Link>
  );
}

CardBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  to: PropTypes.string,
  backgroundImage: PropTypes.object
};
