const skewMenuStyle = {
  menu: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "0",
    marginBottom: "30px",
    marginTop: "30px",
    borderRadius: "2px",
    position: "relative",
    wordWrap: "break-word",
    fontSize: ".875rem",
    transition: "all 300ms linear"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "0",
    boxShadow:
      "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
    marginTop: "30px",
    borderRadius: "2px",
    background: "rgb(255, 250, 255)",
    position: "relative",
    wordWrap: "break-word",
    fontSize: ".875rem",
    transform: "translate3d(0, 0, 0)",
    transition: "all 300ms linear",
    "@media (min-width: 630px)": {
      flexDirection: "row",
      overflow: "hidden"
    }
  }
};
  
export default skewMenuStyle;
  